import { Box, Button, ButtonGroup, LinearProgress, Link } from '@mui/material';
import './App.css';
import React, { useState, useEffect } from 'react';
import { useInterval } from 'usehooks-ts';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import RefreshIcon from '@mui/icons-material/Refresh';
import PublicIcon from '@mui/icons-material/Public';
import PublicIconOff from '@mui/icons-material/PublicOff';
import { BarChart, Bar, Cell, Tooltip, YAxis, XAxis, CartesianGrid, AreaChart, Area } from 'recharts';
import { InstallMobile } from '@mui/icons-material';
import ReactGA from 'react-ga4';

export type BarsProps = {
  width: number;
  height: number;
  events?: boolean;
  data: Array<DataFrame>;
};

interface Pin {
  ecobeePin: string;
  code: string;
  interval: Number;
  expires_in: Number;
  scope: string;
}

interface AccessToken {
  access_token: string;
  refresh_token: string;
}

interface Thermostat {
  identifier: string;
  name: string;
  thermostatRev: string;
  isRegistered: true;
  modelNumber: string;
  brand: string;
  features: string;
  lastModified: string;
  thermostatTime: string;
  utcTime: string;
  equipmentStatus: string;
  runtime: {
    runtimeRev: string;
    connected: true;
    firstConnected: string;
    connectDateTime: string;
    disconnectDateTime: string;
    lastModified: string;
    lastStatusModified: string;
    runtimeDate: string;
    runtimeInterval: number;
    actualTemperature: number;
    actualHumidity: number;
    rawTemperature: number;
    showIconMode: number;
    desiredHeat: number;
    desiredCool: number;
    desiredHumidity: number;
    desiredDehumidity: number;
    desiredFanMode: string;
    actualVOC: number;
    actualCO2: number;
    actualAQAccuracy: number;
    actualAQScore: number;
    desiredHeatRange: Array<number>;
    desiredCoolRange: Array<number>;
  };
}

interface DataFrame {
  date: string;
  time: string;
  auxHeat1: number;
  auxHeat2: number;
  auxHeat3: number;
  compCool1: number;
  compCool2: number;
  compHeat1: number;
  compHeat2: number;
  dehumidifier: number;
  dmOffset: number;
  economizer: number;
  fan: number;
  humidifier: number;
  hvacMode: string; //auto, auxHeatOnly, cool, heat, off
  outdoorTemp: number;
  ventilator: number;
  wind: number;
  zoneAveTemp: number | null;
  zoneCoolTemp: number | null;
  zoneHeatTemp: number | null;
  zoneHvacMode: string; //heatStage1On, heatStage2On, heatStage3On, heatOff, compressorCoolStage1On, compressorCoolStage2On, compressorCoolOff, compressorHeatStage1On, compressorHeatStage2On, compressorHeatOff, economyCycle
}

interface Timeseries {
  start: string;
  end: string;
  data: Array<DataFrame>;
  heated: Boolean;
  cooled: Boolean;
}

interface ZoneData {
  identifier: string;
  data: Array<number>;
  heated: Boolean;
  cooled: Boolean;
}

function App() {
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [durationFilter, setDurationFilter] = useState<number>(1);
  const [interval] = useState<number>(5); // Interval in minutes

  const [apiHostname] = useState<string>(
    window.location.hostname !== 'localhost' ? 'https://ecobee-api.robszumski.com/' : 'http://localhost:3002/',
  );
  const [ecobeePin, setEcobeePin] = useState(localStorage.getItem('ecobeePin'));
  const [ecobeeAuthCode, setEcobeeAuthCode] = useState(localStorage.getItem('ecobeeAuthCode'));
  const [invalidCredentials, setInvalidCredentials] = useState<boolean>(false);
  const [ecobeeRefreshToken, setEcobeeRefreshToken] = useState(localStorage.getItem('ecobeeRefreshToken'));
  const [ecobeeAccessToken, setEcobeeAccessToken] = useState(localStorage.getItem('ecobeeAccessToken'));

  const [thermostats, setThermostats] = useState<Array<Thermostat>>([]);
  const [timeseries, setTimeseries] = useState<{ [key: string]: Timeseries }>({});
  const [zones, setZones] = useState<{ [key: string]: ZoneData }>({});
  const [yMax, setYMax] = useState<number | undefined>();
  const [yMin, setYMin] = useState<number | undefined>();
  const [showOutside, setShowOutside] = useState(localStorage.getItem('showOutside') === 'true');

  const [dataTherostatsCompleted, setDataTherostatsCompleted] = useState<boolean>(false);
  const [dataTimeseriesCompleted, setDataCompleted] = useState<boolean>(false);
  const [lastTimeseriesUpdate, setLastTimeseriesUpdate] = useState<Date | null>(null);
  const [lastZoneUpdate, setLastZoneUpdate] = useState<Date | null>(null);
  const [pinAuthorized, setPinAuthorized] = useState(localStorage.getItem('pinAuthorized'));

  const [delay] = useState<number>(300 * 1000);
  const [isPlaying, setPlaying] = useState<boolean>(false);

  function handleFilter(duration: number) {
    setDurationFilter(duration);
    setDataCompleted(false);
    setTimeseries({});
    setDataTherostatsCompleted(false);

    ReactGA.event({
      category: 'actions',
      action: 'toggle_timeframe',
      value: 1,
    });
  }

  function handleReload() {
    setDataCompleted(false);
    setDataTherostatsCompleted(false);
    setTimeseries({});
    fetchTimeseries();

    let dates = calcDates(durationFilter);
    setStartDate(dates.start);
    setEndDate(dates.end);

    ReactGA.event({
      category: 'actions',
      action: 'refresh',
      value: 1,
    });
  }

  function handleOutside() {
    localStorage.setItem('showOutside', (!showOutside).toString());
    setShowOutside(!showOutside);
  }

  function refreshPage() {
    window.location.reload();
  }

  function calcDates(duration: number) {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - duration);
    const formattedToday = today.toISOString().split('T')[0];
    const formattedYesterday = yesterday.toISOString().split('T')[0];

    return { start: formattedYesterday, end: formattedToday };
  }

  useEffect(() => {
    let dates = calcDates(durationFilter);
    setStartDate(dates.start);
    setEndDate(dates.end);
  }, [durationFilter, startDate, endDate]);

  // authentication
  useEffect(() => {
    const GetPin = async (): Promise<Pin> => {
      // Note: the client_id is added via nginx to keep it secret
      // Upstream API is expecting the client_id to be passed in the URL like this:
      // https://api.ecobee.com/authorize?response_type=ecobeePin&client_id=${key}&scope=smartWrite`;
      var url = `${apiHostname}authorize?response_type=ecobeePin&scope=smartWrite`;

      let response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        let data = await response.json();
        return data;
      } else {
        setInvalidCredentials(true);
        return {
          ecobeePin: '',
          code: '',
          interval: 0,
          expires_in: 0,
          scope: '',
        };
      }
    };

    // this still doesnt work for some reason with malformed request
    const GetToken = async (type: string, authOrRefreshToken: string): Promise<AccessToken> => {
      var url = `${apiHostname}token`;

      try {
        let response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          // Note: the client_id is added via nginx to keep it secret
          // Upstream API is expecting the client_id to be passed in the post body
          body: `grant_type=${type}&code=${authOrRefreshToken}`,
        });

        let data = await response.json();
        return data;
      } catch (error) {
        setInvalidCredentials(true);
        return {
          access_token: '',
          refresh_token: '',
        };
      }
    };

    const fetchData = async () => {
      // fresh app instance
      if (!ecobeePin || !ecobeeAuthCode) {
        try {
          let response = await GetPin();
          setEcobeePin(response.ecobeePin);
          setEcobeeAuthCode(response.code);
          setPinAuthorized('false');
        } catch (error) {
          // Handle errors
          console.error('Error fetching pin:', error);
        }
      }

      // get long-lived creds
      if (
        ecobeePin &&
        ecobeeAuthCode &&
        pinAuthorized === 'true' &&
        (!ecobeeAccessToken || ecobeeAccessToken === '') &&
        (!ecobeeRefreshToken || ecobeeRefreshToken === '')
      ) {
        try {
          let response = await GetToken('ecobeePin', ecobeeAuthCode);
          setEcobeeAccessToken(response.access_token);
          setEcobeeRefreshToken(response.refresh_token);
        } catch (error) {
          // Handle errors
          console.error('Error fetching data:', error);
          setInvalidCredentials(true);
        }
      }

      // refresh creds
      if (ecobeeRefreshToken && ecobeeRefreshToken !== '' && (ecobeeAccessToken === '' || invalidCredentials)) {
        try {
          let response = await GetToken('refresh_token', ecobeeRefreshToken);
          setEcobeeAccessToken(response.access_token);
          setEcobeeRefreshToken(response.refresh_token);
        } catch (error) {
          // Handle errors
          console.error('Error fetching data:', error);
          setInvalidCredentials(true);
        }
      }
    };

    fetchData();

    localStorage.setItem('ecobeeRefreshToken', ecobeeRefreshToken ? ecobeeRefreshToken : '');
    localStorage.setItem('ecobeeAccessToken', ecobeeAccessToken ? ecobeeAccessToken : '');
    localStorage.setItem('ecobeePin', ecobeePin ? ecobeePin : '');
    localStorage.setItem('ecobeeAuthCode', ecobeeAuthCode ? ecobeeAuthCode : '');
    if (invalidCredentials) localStorage.setItem('ecobeeAccessToken', '');
    localStorage.setItem('pinAuthorized', pinAuthorized ? pinAuthorized : '');
  }, [
    apiHostname,
    ecobeeRefreshToken,
    ecobeeAccessToken,
    ecobeePin,
    ecobeeAuthCode,
    invalidCredentials,
    pinAuthorized,
  ]);

  const fetchTimeseries = async () => {
    if (ecobeeAccessToken && (thermostats.length === 0 || !dataTherostatsCompleted)) {
      setThermostats(await ListThemostats(ecobeeAccessToken));
      setDataTherostatsCompleted(true);
    }

    if (ecobeeAccessToken && ecobeeAccessToken !== '' && !dataTimeseriesCompleted && thermostats.length > 0) {
      // Use Promise.all to wait for all async operations to complete
      const newTimeseriesData = await Promise.all(
        thermostats.map(async (thermostat) => {
          if (timeseries[thermostat.identifier] === undefined || isPlaying) {
            try {
              return await GetRuntimeReport(ecobeeAccessToken, thermostat.identifier, startDate, endDate);
            } catch (error) {
              console.log("Error fetching data for thermostat '" + thermostat.identifier + "':", error);
              setInvalidCredentials(true);
              setEcobeeAccessToken('');
              localStorage.setItem('ecobeeAccessToken', '');
              return null;
            }
          }
        }),
      );

      // Combine new data into existing timeseries state
      const modifiedTimeseries = { ...timeseries };
      newTimeseriesData.forEach((newData, index) => {
        if (newData !== undefined && newData !== null) {
          modifiedTimeseries[thermostats[index].identifier] = newData;
        }
      });

      // Update state with the modified timeseries
      setTimeseries(modifiedTimeseries);
      setDataCompleted(true);
      setLastTimeseriesUpdate(new Date());

      // Start background polling
      setPlaying(true);
    }
  };

  // fetch data
  useEffect(() => {
    fetchTimeseries();
  }, [ecobeeAccessToken, thermostats, apiHostname, startDate, endDate, interval, fetchTimeseries]);

  // poll for data
  useInterval(
    () => {
      let dates = calcDates(durationFilter);
      setStartDate(dates.start);
      setEndDate(dates.end);

      setDataCompleted(false);
      setDataTherostatsCompleted(false);
      fetchTimeseries();
    },
    isPlaying ? delay : null,
  );

  // process data
  useEffect(() => {
    let tempColumm = 'zoneAveTemp';
    let modeColumm = 'zoneHvacMode';
    let timeColumn = 'time';
    let localYMax = 0;
    let localYMin = 200;
    var mutated = false;
    if (thermostats.length > 0) {
      let newZone = thermostats.map((therm) => {
        if (timeseries[therm.identifier] !== undefined) {
          mutated = true;
          let rawData = timeseries[therm.identifier].data;
          let zoneData = Array();
          let cooled = false;
          let heated = false;
          Object.values(rawData).map((frame: any) => {
            // capture local min/max for y axis, later compared to global min/max
            if (frame[tempColumm] !== '' && frame[tempColumm] > localYMax) localYMax = frame[tempColumm];
            if (frame[tempColumm] !== '' && frame[tempColumm] < localYMin) localYMin = frame[tempColumm];

            zoneData.push({ value: getHVACValue(frame), time: frame[timeColumn], name: frame[modeColumm] }); //1,2,3 etc
            if (getHeatOrCool(frame[modeColumm]) === 'cool') {
              cooled = true;
            }
            if (getHeatOrCool(frame[modeColumm]) === 'heat') {
              heated = true;
            }

            return null;
          });

          if ((!yMax || yMax < localYMax) && localYMax <= 100) {
            setYMax(localYMax);
          }
          if (!yMin || yMin > localYMin) {
            setYMin(localYMin);
          }

          return { identifier: therm.identifier, data: zoneData, heated: heated, cooled: cooled };
        }

        return null;
      });

      if (mutated && lastTimeseriesUpdate && (!lastZoneUpdate || lastZoneUpdate <= lastTimeseriesUpdate)) {
        const modifiedZones = { ...zones };
        newZone.forEach((newData, index) => {
          if (newData !== undefined && newData !== null) {
            modifiedZones[thermostats[index].identifier] = newData;
          }
        });

        setZones(modifiedZones);
        setLastZoneUpdate(new Date());
      }
    }
  }, [thermostats, timeseries, yMax, yMin, zones, lastTimeseriesUpdate, lastZoneUpdate]);

  const ListThemostats = async (ecobeeAccessToken: string) => {
    const body = {
      selection: {
        selectionType: 'registered',
        selectionMatch: '',
        includeRuntime: true,
        includeEquipmentStatus: true,
        includeWeather: false,
      },
    };

    const apiUrl = `${apiHostname}1/thermostat?format=json&body=${JSON.stringify(body)}`;

    try {
      let response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'text/json',
          Authorization: `Bearer ${ecobeeAccessToken}`,
        },
      });

      let data = await response.json();

      ReactGA.event({
        category: 'thermostats',
        action: 'count',
        value: data.thermostatList.length,
      });

      return data.thermostatList;
    } catch (error) {
      // Handle errors
      localStorage.setItem('ecobeeAccessToken', '');
      setEcobeeAccessToken('');
      setInvalidCredentials(true);
      return [];
    }
  };

  const GetRuntimeReport = async (
    ecobeeAccessToken: string,
    thermostatId: string,
    startDate: string,
    endDate: string,
  ) => {
    const selection = {
      selectionType: 'thermostats',
      selectionMatch: thermostatId,
    };
    let columns = [
      'auxHeat1',
      'auxHeat2',
      'auxHeat3',
      'compCool1',
      'compCool2',
      'compHeat1',
      'compHeat2',
      'dehumidifier',
      'dmOffset',
      'economizer',
      'fan',
      'humidifier',
      'hvacMode',
      'outdoorTemp',
      'ventilator',
      'wind',
      'zoneAveTemp',
      'zoneCoolTemp',
      'zoneHeatTemp',
      'zoneHvacMode',
    ];
    const now = new Date();
    const midnightUTC = new Date(now.toISOString().split('T')[0] + 'T00:00:00Z');
    const intervalsSinceMidnight = Math.floor((now.getTime() - midnightUTC.getTime()) / (5 * 60 * 1000));
    const apiUrl = `${apiHostname}1/runtimeReport?format=json&body={"selection":${JSON.stringify(
      selection,
    )},"startDate":"${startDate}","endDate":"${endDate}","columns":"${columns.join(
      ',',
    )}","startInterval":${intervalsSinceMidnight},"endInterval":${intervalsSinceMidnight - 1}}`;

    try {
      let response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ecobeeAccessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`);
      }

      let data = await response.json();
      // push data frames into timeseries
      let newTimeseries: Timeseries = {
        start: data.startDate,
        end: data.endDate,
        data: [],
        heated: false,
        cooled: false,
      };
      data.reportList[0].rowList.forEach((frame: any) => {
        frame = frame.split(',');
        let newFrame: DataFrame = {
          date: frame[0],
          time: formatTime(frame[1]),
          auxHeat1: frame[2],
          auxHeat2: frame[3],
          auxHeat3: frame[4],
          compCool1: frame[5],
          compCool2: frame[6],
          compHeat1: frame[7],
          compHeat2: frame[8],
          dehumidifier: frame[9],
          dmOffset: frame[10],
          economizer: frame[11],
          fan: frame[12],
          humidifier: frame[13],
          hvacMode: frame[14],
          outdoorTemp: frame[15],
          ventilator: frame[16],
          wind: frame[17],
          zoneAveTemp: emptyToNull(frame[18]),
          zoneCoolTemp: emptyToNull(frame[19]),
          zoneHeatTemp: emptyToNull(frame[20]),
          zoneHvacMode: frame[21],
        };
        newTimeseries.data.push(newFrame);
      });

      return newTimeseries;
    } catch (error) {
      console.log("Error fetching data for thermostat '" + thermostatId + "':", error);
      setInvalidCredentials(true);
      localStorage.setItem('ecobeeAccessToken', '');
    }
  };

  useEffect(() => {
    // run tracking if not localhost
    if (!window.location.hostname.startsWith('localhost')) {
      ReactGA.initialize('G-DT4EYQ3KNY');
    }
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div>
          {ecobeePin && ecobeeAuthCode && pinAuthorized !== 'true' && (
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ textAlign: 'center', marginTop: '40px', position: 'relative' }}>
                <h2>Welcome to Ecobee Overview</h2>
                <Box sx={{ '& ol': { display: 'inline-block', textAlign: 'left' } }}>
                  To authorize the app, do the following:
                  <br />
                  <ol>
                    <li>Login to your Ecobee account</li>
                    <li>Navigate to "My Apps"</li>
                    <li>Enter the following pin:</li>
                  </ol>
                </Box>
                <Box
                  sx={{
                    background: '#fff',
                    color: '#333',
                    padding: '30px 60px',
                    display: 'inline-block',
                    marginTop: '10px',
                  }}
                >
                  {ecobeePin}
                </Box>
                <Button
                  variant="contained"
                  sx={{ display: 'block', margin: '50px auto 0 auto' }}
                  onClick={() => {
                    console.log('Pin was authorized');
                    setPinAuthorized('true');
                  }}
                >
                  Ok, I've done that
                </Button>
              </Box>
              <Box
                sx={{
                  maxWidth: '200px',
                  marginTop: '40px',
                  marginLeft: '40px',
                  '& img': {
                    borderRadius: '6px',
                    maxWidth: '100%',
                    maxHeight: '100%',
                  },
                  display: { xs: 'none', sm: 'block' },
                }}
              >
                <img
                  src="../screenshot.png"
                  alt="Ecobee Overview screenshot showing multiple thermostats, heat modes, and set temperature."
                />
              </Box>
            </Box>
          )}
          {pinAuthorized === 'true' && (
            <>
              <Box
                sx={{
                  border: '1px solid #16346a',
                  borderRadius: '8px',
                  background: '#132243',
                  margin: '30px 10px 10px 10px',
                  boxShadow: '0px 0px 50px #040712',
                  minWidth: '360px',
                }}
              >
                <Box
                  sx={{
                    padding: '13px 20px 15px 20px',
                    display: 'flex',
                    flexDirection: 'row',
                    borderBottom: '1px solid #16346a',
                    justifyContent: 'space-between',
                    '&:last-child': { borderBottom: 'none' },
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => handleReload()}
                    sx={{ alignSelf: 'flex-end' }}
                    size="small"
                  >
                    <RefreshIcon sx={{}} />
                  </Button>
                  <Button
                    variant={showOutside ? 'contained' : 'outlined'}
                    onClick={() => handleOutside()}
                    sx={{ alignSelf: 'flex-end' }}
                    size="small"
                  >
                    {showOutside ? <PublicIcon /> : <PublicIconOff />}
                  </Button>
                  <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                    size="small"
                    sx={{
                      alignSelf: 'flex-start',
                      '& button': { textTransform: 'lowercase', fontWeight: 600, fontSize: '15px' },
                    }}
                  >
                    <Button onClick={() => handleFilter(1)}>1d</Button>
                    <Button onClick={() => handleFilter(3)}>3d</Button>
                  </ButtonGroup>
                </Box>
                {thermostats &&
                  thermostats
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((thermostat, index) => {
                      return (
                        <React.Fragment key={thermostat.identifier}>
                          {index === 0 && showOutside && (
                            <Box
                              key={'outside-' + thermostat.identifier}
                              sx={{
                                padding: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                borderBottom: '1px solid #16346a',
                                '&:last-child': { borderBottom: 'none' },
                              }}
                            >
                              <Box
                                sx={{
                                  flexGrow: 1,
                                  width: '100%',
                                  textAlign: 'left',
                                  marginBottom: '10px',
                                  display: 'flex',
                                }}
                              >
                                <Box sx={{ textAlign: 'left', width: '70%' }}>Outside</Box>
                                <Box sx={{ textAlign: 'right', width: '30%' }}>
                                  {timeseries && timeseries[thermostat.identifier] && (
                                    <Box>
                                      {
                                        timeseries[thermostat.identifier].data[
                                          timeseries[thermostat.identifier].data.length - 1
                                        ].outdoorTemp
                                      }
                                      &deg;
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  width: '100%',
                                  flexDirection: 'row',
                                }}
                              >
                                <Box sx={{ minWidth: '60px', textAlign: 'left', height: '20px' }}></Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexGrow: '1',
                                    flexDirection: 'column',
                                    height: '75px',
                                    width: '260px',
                                  }}
                                >
                                  {startDate &&
                                  endDate &&
                                  timeseries &&
                                  timeseries[thermostat.identifier] &&
                                  timeseries[thermostat.identifier].data ? (
                                    <React.Fragment>
                                      <Box
                                        sx={{
                                          position: 'relative',
                                          width: '260px',
                                          textAlign: 'left',
                                          marginTop: '5px',
                                        }}
                                      >
                                        <AreaChart
                                          width={260}
                                          height={70}
                                          data={timeseries[thermostat.identifier]['data']}
                                          margin={{
                                            top: 0,
                                            right: 0,
                                            left: 0,
                                            bottom: 0,
                                          }}
                                          syncId="sync"
                                        >
                                          <CartesianGrid strokeOpacity={0.1} />
                                          <XAxis
                                            dataKey="time"
                                            hide={false}
                                            tick={{ fontSize: 8 }}
                                            height={20}
                                            minTickGap={20}
                                          />
                                          <YAxis
                                            dataKey="outdoorTemp"
                                            width={25}
                                            tick={{ fontSize: 8 }}
                                            allowDataOverflow={false}
                                            padding={{ top: 10, bottom: 0 }}
                                            interval={'preserveStartEnd'}
                                            allowDecimals={false}
                                            yAxisId="right"
                                            orientation="left"
                                            hide={false}
                                          />
                                          <Tooltip
                                            itemStyle={{ color: 'black', fontSize: '11px' }}
                                            wrapperStyle={{
                                              fontSize: '11px',
                                              display: 'inline-block',
                                              color: 'black',
                                              fontWeight: '600',
                                            }}
                                            contentStyle={{ color: '#red', border: 'none' }}
                                            position={{ y: -60 }}
                                          />
                                          <Area
                                            type="linear"
                                            stroke="green"
                                            fill="rgba(0, 255, 0, 0.1)"
                                            dataKey="outdoorTemp"
                                            isAnimationActive={false}
                                            dot={false}
                                            hide={false}
                                            yAxisId="right"
                                          />
                                        </AreaChart>
                                      </Box>
                                    </React.Fragment>
                                  ) : (
                                    <>
                                      <Box
                                        sx={{
                                          background: 'rgba(255,255,255,.02)',
                                          height: '75px',
                                          width: '240px',
                                          margin: '10px 0 10px 20px',
                                        }}
                                      ></Box>
                                    </>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          )}
                          <Box
                            key={thermostat.identifier}
                            sx={{
                              padding: '20px',
                              display: 'flex',
                              flexDirection: 'column',
                              borderBottom: '1px solid #16346a',
                              '&:last-child': { borderBottom: 'none' },
                            }}
                          >
                            <Box
                              sx={{
                                flexGrow: 1,
                                width: '100%',
                                textAlign: 'left',
                                marginBottom: '10px',
                                display: 'flex',
                              }}
                            >
                              <Box sx={{ textAlign: 'left', width: '70%' }}>{thermostat.name}</Box>
                              <Box sx={{ textAlign: 'right', width: '30%' }}>
                                {String(thermostat.runtime.actualTemperature / 10)}&deg;
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'row',
                              }}
                            >
                              <Box sx={{ minWidth: '60px', textAlign: 'left' }}>
                                <Box>
                                  {timeseries &&
                                    timeseries[thermostat.identifier] &&
                                    timeseries[thermostat.identifier].data && (
                                      <Box
                                        sx={{
                                          borderRadius: '3px',
                                          display: 'inline-block',
                                          color:
                                            getHeatOrCool(thermostat.equipmentStatus) === 'cool'
                                              ? getHVACColorFromEquipment(thermostat.equipmentStatus)
                                              : '#fff',
                                          padding: '3px 6px 1px 2px',
                                          lineHeight: 1,
                                          marginLeft: '-6px',
                                          borderWidth:
                                            getHeatOrCool(thermostat.equipmentStatus) === 'cool' ? '0px' : '0px',
                                          borderStyle: 'solid',
                                        }}
                                        data-status={thermostat.equipmentStatus}
                                        className={`
                                          ${'cool '} + ${
                                          getHeatOrCool(thermostat.equipmentStatus) === 'cool' ? 'active' : 'inactive'
                                        }`}
                                      >
                                        <AcUnitIcon
                                          sx={{
                                            color:
                                              getHeatOrCool(thermostat.equipmentStatus) === 'cool'
                                                ? getHVACColorFromEquipment(thermostat.equipmentStatus)
                                                : '#193d7c',
                                            fontSize: '24px',
                                          }}
                                        />
                                        <Box
                                          sx={{
                                            display: 'inline-block',
                                            fontSize: '11px',
                                            lineHeight: '24px',
                                            verticalAlign: 'top',
                                            marginLeft: '5px',
                                          }}
                                        >
                                          {thermostat.runtime.desiredCool / 10}
                                          &deg;
                                        </Box>
                                      </Box>
                                    )}
                                </Box>
                                <Box>
                                  {timeseries &&
                                    timeseries[thermostat.identifier] &&
                                    timeseries[thermostat.identifier].data && (
                                      <Box
                                        sx={{
                                          borderRadius: '3px',
                                          display: 'inline-block',
                                          color:
                                            getHeatOrCool(thermostat.equipmentStatus) === 'heat'
                                              ? getHVACColorFromEquipment(thermostat.equipmentStatus)
                                              : '#fff',
                                          padding: '3px 6px 1px 2px',
                                          lineHeight: 1,
                                          marginLeft: '-6px',
                                          borderWidth:
                                            getHeatOrCool(thermostat.equipmentStatus) === 'heat' ? '0px' : '0px',
                                          borderStyle: 'solid',
                                        }}
                                        data-status={thermostat.equipmentStatus}
                                        className={`
                                          ${'heat '} + ${
                                          getHeatOrCool(thermostat.equipmentStatus) === 'heat' ? 'active' : 'inactive'
                                        }`}
                                      >
                                        <LocalFireDepartmentIcon
                                          sx={{
                                            color:
                                              getHeatOrCool(thermostat.equipmentStatus) === 'heat'
                                                ? getHVACColorFromEquipment(thermostat.equipmentStatus)
                                                : '#193d7c',
                                          }}
                                        />
                                        <Box
                                          sx={{
                                            display: 'inline-block',
                                            fontSize: '11px',
                                            lineHeight: '24px',
                                            verticalAlign: 'top',
                                            marginLeft: '5px',
                                          }}
                                        >
                                          {thermostat.runtime.desiredHeat / 10}
                                          &deg;
                                        </Box>
                                      </Box>
                                    )}
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexGrow: '1',
                                  flexDirection: 'column',
                                  height: '95px',
                                  width: '260px',
                                }}
                              >
                                {yMin !== undefined &&
                                yMin &&
                                yMax !== undefined &&
                                yMax &&
                                timeseries &&
                                timeseries[thermostat.identifier] &&
                                zones[thermostat.identifier] ? (
                                  <React.Fragment key={thermostat.identifier}>
                                    <Box
                                      sx={{
                                        position: 'relative',
                                        width: '100%',
                                        textAlign: 'left',
                                        marginBottom: '5px',
                                      }}
                                    >
                                      <BarChart
                                        width={260}
                                        height={20}
                                        margin={{ left: 25 }}
                                        data={zones[thermostat.identifier].data}
                                        syncId="bars"
                                      >
                                        <XAxis dataKey="time" hide={true} />
                                        <Bar dataKey="value" isAnimationActive={false} barSize={1}>
                                          {zones[thermostat.identifier].data.map((entry: any, index) => (
                                            <Cell
                                              cursor="pointer"
                                              fill={getHVACColorFromNumber(entry.value)}
                                              key={`cell-${index}`}
                                            />
                                          ))}
                                        </Bar>
                                        <Tooltip
                                          itemStyle={{ color: 'black', fontSize: '11px' }}
                                          wrapperStyle={{
                                            fontSize: '11px',
                                            display: 'inline-block',
                                            color: 'black',
                                            fontWeight: '600',
                                          }}
                                          position={{ y: -55 }}
                                          formatter={(value: any, name: any, props: any) => {
                                            return [friendlyModeDisplay(props.payload.name)];
                                          }}
                                        />
                                      </BarChart>
                                    </Box>
                                    <Box
                                      sx={{
                                        position: 'relative',
                                        width: '260px',
                                        textAlign: 'left',
                                      }}
                                    >
                                      <AreaChart
                                        width={260}
                                        height={70}
                                        data={timeseries[thermostat.identifier]['data']}
                                        margin={{
                                          top: 0,
                                          right: 0,
                                          left: 0,
                                          bottom: 0,
                                        }}
                                        syncId="sync"
                                      >
                                        <CartesianGrid strokeOpacity={0.1} />
                                        <XAxis
                                          dataKey="time"
                                          hide={false}
                                          tick={{ fontSize: 8 }}
                                          height={20}
                                          minTickGap={20}
                                        />
                                        <YAxis
                                          dataKey="zoneAveTemp"
                                          domain={[Math.floor(yMin), Math.ceil(yMax)]}
                                          width={25}
                                          tick={{ fontSize: 8 }}
                                          tickCount={10}
                                          allowDataOverflow={true}
                                          includeHidden={true}
                                          padding={{ top: 4, bottom: 0 }}
                                          interval={'equidistantPreserveStart'}
                                          allowDecimals={false}
                                          yAxisId="left"
                                        />
                                        <YAxis
                                          dataKey="outdoorTemp"
                                          width={25}
                                          tick={{ fontSize: 8 }}
                                          allowDataOverflow={false}
                                          // padding={{ top: 4, bottom: 4 }}
                                          interval={'equidistantPreserveStart'}
                                          allowDecimals={false}
                                          yAxisId="right"
                                          orientation="right"
                                          hide={true}
                                        />
                                        <Tooltip
                                          itemStyle={{ color: 'black', fontSize: '11px' }}
                                          wrapperStyle={{
                                            fontSize: '11px',
                                            display: 'inline-block',
                                            color: 'black',
                                            fontWeight: '600',
                                          }}
                                          formatter={(value: any, name: any, props: any) => {
                                            return [value, friendlyModeDisplay(props.name)];
                                          }}
                                          position={{ y: -100 }}
                                        />
                                        <Area
                                          type="bumpY"
                                          stroke="#fff"
                                          fill="rgba(255, 255, 255, 0.2 )"
                                          dataKey="zoneAveTemp"
                                          isAnimationActive={false}
                                          dot={false}
                                          yAxisId="left"
                                        />
                                        <Area
                                          type="bumpY"
                                          stroke="green"
                                          dataKey="outdoorTemp"
                                          isAnimationActive={false}
                                          dot={false}
                                          hide={true}
                                          yAxisId="right"
                                        />
                                        <Area
                                          type="bumpY"
                                          fill="transparent"
                                          stroke="#ED7356"
                                          dataKey="zoneHeatTemp"
                                          isAnimationActive={false}
                                          dot={false}
                                          hide={zones[thermostat.identifier].heated ? false : true}
                                          yAxisId="left"
                                        />
                                        <Area
                                          type="bumpY"
                                          fill="transparent"
                                          stroke="#3552BD"
                                          dataKey="zoneCoolTemp"
                                          isAnimationActive={false}
                                          dot={false}
                                          hide={zones[thermostat.identifier].cooled ? false : true}
                                          yAxisId="left"
                                        />
                                      </AreaChart>
                                    </Box>
                                  </React.Fragment>
                                ) : (
                                  <>
                                    {/* <Box>timeseries {timeseries ? 'true' : 'false'}</Box>
                                <Box>ymin{yMin ? 'true' : 'false'}</Box>
                                <Box>ymax{yMax ? 'true' : 'false'}</Box>
                                <Box>data{timeseries[thermostat.identifier] ? 'true' : 'false'}</Box>
                                <Box>zones{timeseries[thermostat.identifier] ? 'true' : 'false'}</Box>
                                  <Box>completed{dataTimeseriesCompleted ? 'true' : 'false'}</Box> */}
                                    <Box
                                      sx={{
                                        background: 'rgba(255,255,255,.02)',
                                        height: '75px',
                                        width: '240px',
                                        margin: '10px 0 10px 20px',
                                      }}
                                    ></Box>
                                  </>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </React.Fragment>
                      );
                    })}
                {thermostats.length === 0 && (
                  <Box
                    sx={{
                      padding: '40px 20px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box sx={{ textAlign: 'center', width: '100%' }}>Loading your account...</Box>
                    <LinearProgress
                      sx={{
                        display: 'block',
                        width: '150px',
                        margin: '20px auto 0 auto',
                        backgroundColor: 'rgba(0,0,0,.2)',
                      }}
                    />
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  fontSize: '12px',
                  color: '#193d7c',
                  marginBottom: '20px',
                  lineHeight: '20px',
                  marginTop: '15px',
                  '& a:hover': { cursor: 'pointer' },
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '0 10px',
                }}
              >
                <Box sx={{ textAlign: 'left' }}>
                  <InstallMobile sx={{ width: '20px', height: '20px', marginRight: '5px', verticalAlign: 'middle' }} />
                  Tip: Add to your homescreen
                </Box>
                <Box sx={{ textAlign: 'right' }}>
                  <Link onClick={refreshPage} sx={{ display: 'block' }}>
                    Reset Everything
                  </Link>
                  <Link
                    href="mailto:&#114;&#111;&#98;&#64;&#114;&#111;&#98;&#115;&#122;&#117;&#109;&#115;&#107;&#105;&#46;&#99;&#111;&#109;?subject=Feedback about ecobeeoverview.com"
                    sx={{ fontSize: '12px', marginBottom: '20px', lineHeight: '12px' }}
                  >
                    Send Feedback
                  </Link>
                </Box>
              </Box>
            </>
          )}
        </div>
      </header>
    </div>
  );
}

export default App;

const getHVACColorFromEquipment = (status: string) => {
  if (status !== '') {
    if (status.includes('heatPump')) {
      return '#EEAF4E';
    } else if (status.includes('auxHeat1')) {
      return '#EEAF4E';
    } else if (status.includes('auxHeat2') || status.includes('auxHeat3')) {
      return '#ff0000';
    } else if (status.includes('compCool1')) {
      return '#5379DB';
    } else if (status.includes('compCool2')) {
      return '#3552BD';
    } else {
      return '#193d7c';
    }
  } else {
    return '#193d7c';
  }
};

const getHeatOrCool = (status: string) => {
  if (status.length > 0) {
    if (status.includes('heatPump') || status.includes('heatStage') || status.includes('compressorHeat')) {
      return 'heat';
    } else if (status.includes('auxHeat1')) {
      return 'heat';
    } else if (status.includes('auxHeat2') || status.includes('auxHeat3')) {
      return 'heat';
    } else if (status.includes('compCool1')) {
      return 'cool';
    } else if (status.includes('compCool2')) {
      return 'cool';
    } else if (status.includes('compressorCoolStage1On') || status.includes('compressorCoolStage2On')) {
      return 'cool';
    } else if (status.includes('fan')) {
      return 'off';
    } else if (status.includes('Off')) {
      return 'off';
    } else {
      return 'off';
    }
  } else {
    return 'off';
  }
};

const getHVACValue = (d: DataFrame) => {
  switch (d.zoneHvacMode) {
    case 'heatStage1On':
      return 1;
    case 'heatStage2On':
    case 'auxStage1OnWithHP':
    case 'auxHeat1':
      return 2;
    case 'heatStage3On':
    case 'auxHeat2':
    case 'auxHeat3':
      return 3;
    case 'heatOff':
      return 0;
    case 'coolOff':
      return 0;
    case 'compressorCoolStage1On':
      return -1;
    case 'compressorCoolStage2On':
      return -2;
    case 'compressorCoolOff':
      return 0;
    case 'compressorHeatStage1On':
      return 1;
    case 'compressorHeatStage2On':
      return 2;
    case 'compressorHeatOff':
      return 0;
    case 'economyCycle':
      return 0;
    default:
      return 0;
  }
};

const getHVACColorFromNumber = (d: number) => {
  switch (d) {
    case 1:
      return '#EEAF4E';
    case 2:
      return '#ED7356';
    case 3:
      return '#ff0000';
    case 0:
      return 'transparent';
    case -1:
      return '#5379DB';
    case -2:
      return '#3552BD';
    default:
      return 'transparent';
  }
};

const formatTime = (input: string) => {
  let output = new Date(`2000-01-01T${input}`).toLocaleTimeString([], {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  return output;
};

const emptyToNull = (value: any) => {
  if (value === '') {
    return;
  } else {
    return value;
  }
};

const friendlyModeDisplay = (mode: string) => {
  const camelWithSpaces = mode.split(/(?=[A-Z])/);
  const capitalizedString = camelWithSpaces.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  const finalString = capitalizedString
    .replace('On', '')
    .replace('H P', 'HP')
    .replace('Ave', 'Average')
    .replace('Temp', '');
  return mode.length > 0 ? finalString : 'Not Reported Yet';
};
